import axios,{post,get} from '@/axios'


export const ssoLogin = (params)=>{
  return post("/ssoAuth/sso/login",params)
}

export const ssoAuth = (params)=>{
  return post("/ssoAuth/sso/auth",params)
}

// export const logout = (params)=>{
//   return post("/ssoAuth/logout",params)
// }

// export const updatePassword = (params)=>{
//   return axios("PUT","/ssoAuth/basedata/user/updatePassword",params)
// }

export const fsLogin = (params)=>{
  return post("/ssoAuth/sso/fslogin",params)
}
 
// export const adminLoginApi = (params)=>{
//   return post("/ssoAuth/login",params)
// }

 
// export const getRoutersApi = (params)=>{
//   return post("/basedata/menu/getRouters?systemCode=SUPP_COOPERATE",params)
// }


