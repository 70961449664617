import {
  createRouter,
  createWebHistory
} from 'vue-router'

import login from '@/views/login/index.vue'

function _import(path) {
  return () => import("@/views" + path)
}
const routes = [{
    path: '',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



export default router