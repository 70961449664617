import {
  createStore
} from 'vuex'
let tagsRouter = localStorage.getItem("tagsRouter");
tagsRouter = tagsRouter ? JSON.parse(tagsRouter) : []
// let initTagsRouter ={
//   close: false,
//   name: "首页",
//   path: "/index",
//   query: {},
// }
// if (tagsRouter.length == 0) {
//   tagsRouter.push(initTagsRouter)
// }
export default createStore({
  state: {
    userInfo: {},
    tagsRouter: tagsRouter,
    menuList:[],
    paths: [],
    routerFinished: 0,
  },
  getters: {},
  mutations: {
    clearTagsRouter(state) {
      state.tagsRouter = [];
      localStorage.removeItem("tagsRouter")
    },
    routerFinished(state, status) {
      state.routerFinished = status;
    },
    paths(state, paths) {
      state.paths = paths;
    },
    menuList(state, list) {
      state.menuList = list;
    },
    userInfo(state, info) {
      state.userInfo = info;
    },
    initTagsRouter(state){
      state.tagsRouter = [];
      localStorage.setItem("tagsRouter", JSON.stringify(state.tagsRouter))
    },
    tagsRouterSplice(state, index) {
      state.tagsRouter.splice(index, 1);
      localStorage.setItem("tagsRouter", JSON.stringify(state.tagsRouter))
    },
    addTagsRouter(state, {
      name,
      path,
      query
    }) {
      let findIndex = state.tagsRouter.findIndex(i => i.path == path);
      if (findIndex != -1) {
        state.tagsRouter[findIndex] = {
          close: true,
          name: name,
          path: path,
          query: query,
        }
      } else {
        state.tagsRouter.push({
          close: true,
          name: name,
          path: path,
          query: query,
        })
      }
      localStorage.setItem("tagsRouter", JSON.stringify(state.tagsRouter))
    },
  },
  actions: {},
  modules: {}
})